import React, { useContext } from 'react';
import { LocalContext } from 'context';
import { breakpoints } from 'styles';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import Poll1 from 'assets/images/docs/poll1.png';
import Poll2 from 'assets/images/docs/poll2.png';
import Poll6 from 'assets/images/docs/poll6.png';
import Poll7 from 'assets/images/docs/poll7.png';
import Feedback2 from 'assets/images/docs/feedback2.png';
import Feedback3 from 'assets/images/docs/feedback3.png';
import Feedback4 from 'assets/images/docs/feedback4.png';
import Feedback5 from 'assets/images/docs/feedback5.png';
import Feedback6 from 'assets/images/docs/feedback6.png';
import Feedback7 from 'assets/images/docs/feedback7.png';
import Wordcloud1 from 'assets/images/docs/wordcloud1.png';
import Wordcloud2 from 'assets/images/docs/wordcloud2.png';
import Wordcloud3 from 'assets/images/docs/wordcloud3.png';
import Wordcloud4 from 'assets/images/docs/wordcloud4.png';
import Wordcloud5 from 'assets/images/docs/wordcloud5.png';
import Wordcloud6 from 'assets/images/docs/wordcloud6.png';
import Wordcloud7 from 'assets/images/docs/wordcloud7.png';
import Wordcloud8 from 'assets/images/docs/wordcloud8.png';

const PollDocumentation = () => {
  const { theme, selectedPollDocumentation } = useContext(LocalContext);
  return (
    <Wrapper theme={theme}>
      <AnimatePresence>
        <Content
          initial={{
            opacity: 0
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.9
            }
          }}
          exit={{
            opacity: 0,
            transition: {
              duration: 0.6
            }
          }}>
          <h2>Polls Guidelines</h2>
          <h3>
            As a moderator you have access to the Moderator&apos;s Control Panel from within the
            Polls feature of the livestream event. Here you can create, edit and open polls that
            encourage participation from viewers. You can then view or share the results with
            participants.
          </h3>
          <FigureWrapper>
            <Figure>
              <Image src={Poll1} />
              <ImageCaption style={{ textAlign: 'center' }}>
                To create a new poll select <strong>CREATE POLL</strong> and choose which type of
                poll you would like to create from the dropdown menu.
              </ImageCaption>
            </Figure>
            {selectedPollDocumentation === 'general' && (
              <>
                <Figure>
                  <Image src={Poll2} />
                  <ImageCaption>
                    Fill in all necessary fields. You must provide a question and two or more
                    answers for participants to choose from. If applicable, choose one or more
                    options from the checklist to add additional settings to the poll.
                    <br />
                    <br />
                    Select <strong>SAVE</strong>
                  </ImageCaption>
                </Figure>
                <h4>Choosing Additional Settings</h4>
                <Figure>
                  <Option>
                    <ImageCaption style={{ margin: '0 1rem' }}>
                      <strong>Marking correct answer/s</strong>
                      <br />
                      <br />
                      If the question has a predefined answer/s, choose either one or more correct
                      answers from the options you provided.
                    </ImageCaption>
                    {/* src="https://player.vimeo.com/video/663293187?autoplay=1&loop=1&autopause=0" */}
                    <iframe
                      title="vimeo-player"
                      src="https://player.vimeo.com/video/663293187?h=a88542641e&badge=0&autoplay=1&loop=1&autopause=0&player_id=0&amp;app_id=58479"
                      height="340"
                      frameBorder="0"
                      allow="autoplay"
                    />
                  </Option>
                  <Option>
                    <ImageCaption style={{ margin: '0 1rem' }}>
                      <strong>Allow multiple choice</strong>
                      <br />
                      <br />
                      Allows participants to select one or more answers from the options you
                      provided. If selected the default option allows participants to select all
                      answers, you can change this setting by selecting the dropdown and choosing
                      how many answers a partcipant can select.
                    </ImageCaption>
                    <iframe
                      title="vimeo-player"
                      src="https://player.vimeo.com/video/663293251?h=b087c3af01&badge=0&player_id=0&app_id=58479&autoplay=1&loop=1&autopause=0"
                      height="340"
                      frameBorder="0"
                      allow="autoplay"
                    />
                  </Option>
                  <Option>
                    <ImageCaption style={{ margin: '0 1rem' }}>
                      <strong>Enabling a timer</strong>
                      <br />
                      <br />
                      Sets a timer for which participants can submit their answer/s. The poll will
                      still continue to display to viewers but they can no longer make submissions.
                    </ImageCaption>
                    <iframe
                      title="vimeo-player"
                      src="https://player.vimeo.com/video/663292651?h=4138fd39db&badge=0&player_id=0&app_id=58479&autoplay=1&loop=1&autopause=0"
                      height="340"
                      frameBorder="0"
                      allow="autoplay"
                    />
                  </Option>
                  <ImageCaption style={{ margin: '0 1rem' }}>
                    <br />
                    To save these settings, select <strong>SAVE</strong>.
                  </ImageCaption>
                </Figure>
                <h4>Edit or Delete Polls</h4>
                <Figure>
                  <Image src={Poll6} />
                  <ImageCaption>
                    The poll can then be found filed under Queued Polls. At anytime before opening a
                    poll you&apos;ve created, you can go back and make an edit.
                    <br />
                    <br />
                    <strong>Edit</strong> allows you to make changes to poll settings.{' '}
                    <strong>Delete</strong> allows you to permanently delete a poll, along with any
                    corresponding analytics.
                  </ImageCaption>
                </Figure>
                <h4>Opening a Poll to Viewers & Partcipants</h4>
                <Figure>
                  <Image src={Poll7} />
                  <ImageCaption>
                    Select your newly created poll under Queued Poll, then select{' '}
                    <strong>OPEN POLL</strong> to display the poll to viewers and partcipants.
                    <br />
                    <br />
                    Viewers will be presented with a question and answers to choose from. If a
                    viewer decides to participate, they will choose their answer/s and select{' '}
                    <strong>SUBMIT</strong>.
                    <br />
                    <br />
                    When you a ready to close a poll select <strong>CLOSE POLL</strong>. Once a poll
                    is closed it will then be filed under Closed Polls, analytics will be available
                    to show how many viewers participated and how those participants answered.
                  </ImageCaption>
                </Figure>
                <h4>Viewing & Sharing Poll Analytics</h4>
                <Figure>
                  <iframe
                    title="vimeo-player"
                    src="https://player.vimeo.com/video/663292427?h=6cb4e6fc29&badge=0&player_id=0&app_id=58479&autoplay=1&loop=1&autopause=0"
                    width="100%"
                    height="260"
                    frameBorder="0"
                    allow="autoplay"
                  />
                  <ImageCaption>
                    You can choose to view poll analytics privately and/or share these analytics
                    with viewers. Select <strong>View Analytics</strong> to see a breakdown of the
                    poll analytics in the control panel. Select <strong>SHARE ANALYTICS</strong> to
                    share a breakdown of the poll analytics with viewers and participants.
                    <br />
                    <br />
                    To stop sharing poll analytics with viewers, select{' '}
                    <strong>STOP SHARING</strong>.
                  </ImageCaption>
                </Figure>
              </>
            )}
            {selectedPollDocumentation === 'feedback' && (
              <>
                <Figure>
                  {/* <Image src={Poll2} /> */}
                  <ImageCaption>
                    <div style={{ display: 'flex' }}>
                      <p>
                        Fill in all necessary fields. You <strong>must</strong> provide a question.
                        A rating option will be selected by default or you can select a prefered
                        option. If applicable, choose one or more options from the checklist to add
                        additional fields to the poll.
                        <br />
                        <br />
                        To save these settings without any additional fields, select{' '}
                        <strong>SAVE</strong>
                      </p>
                      <iframe
                        title="vimeo-player"
                        src="https://player.vimeo.com/video/664223858?h=037f398cee&badge=0&player_id=0&app_id=58479&autoplay=1&loop=1&autopause=0"
                        height="340"
                        frameBorder="0"
                        allow="autoplay"
                      />
                    </div>
                  </ImageCaption>
                </Figure>
                <h4>Choosing Additional Fields</h4>
                <Figure>
                  <Option>
                    <ImageCaption style={{ margin: '0 1rem' }}>
                      <strong>Allow participants to provide their own personal feedback</strong>
                      <br />
                      <br />
                      Participants have the option to write a personal message which will be
                      submitted along with their chosen rating. <br />
                      <br />
                      Any submitted personal messages will not be available while viewing or sharing
                      analytics.
                    </ImageCaption>
                    <Image src={Feedback2} style={{ maxWidth: '18.75rem' }} />
                  </Option>
                  <Option>
                    <ImageCaption style={{ margin: '0 1rem' }}>
                      <strong>
                        Ask participants for permission to contact them relating their submission
                      </strong>
                      <br />
                      <br />
                      If you would like to reach out to participants in relation to their
                      submission, the field provides participants with the option to opt-out from
                      being contacted.
                    </ImageCaption>
                    <Image src={Feedback3} style={{ maxWidth: '18.75rem' }} />
                  </Option>
                  <ImageCaption style={{ margin: '0 1rem' }}>
                    <br />
                    To save these settings, select <strong>SAVE</strong>.
                  </ImageCaption>
                </Figure>
                <h4>Edit or Delete Polls</h4>
                <Figure>
                  <Image src={Feedback4} />
                  <ImageCaption>
                    The poll can then be found filed under Queued Polls. At anytime before opening a
                    poll you&apos;ve created, you can go back and make an edit.
                    <br />
                    <br />
                    <strong>Edit</strong> allows you to make changes to poll settings.{' '}
                    <strong>Delete</strong> allows you to permanently delete a poll, along with any
                    corresponding analytics.
                  </ImageCaption>
                </Figure>
                <h4>Opening a Poll to Viewers & Partcipants</h4>
                <Figure>
                  <Image src={Feedback6} />
                  <ImageCaption>
                    Select your newly created poll under Queued Poll, then select{' '}
                    <strong>OPEN POLL</strong> to display the poll to viewers and partcipants.
                  </ImageCaption>
                </Figure>
                <Figure>
                  <Image src={Feedback5} />
                  <ImageCaption>
                    Viewers will be presented with a question, a rating score range to select from
                    and if applicable the options to submit a personal message and/or opt-out of
                    being contacted regarding their feedback.
                  </ImageCaption>
                  <Image src={Feedback7} />
                  <ImageCaption>
                    If a viewer decides to participate, they will need to at least choose their
                    rating score before submitting their answer. <br />
                    <br />
                    <small>
                      It is not mandatory for a participant to provide a personal message, but they
                      may want to opt-out from being contacted.
                    </small>
                  </ImageCaption>
                </Figure>
                <Figure>
                  <ImageCaption>
                    When you a ready to close a poll select <strong>CLOSE POLL</strong>. Once a poll
                    is closed it will then be filed under Closed Polls, analytics will be available
                    to show how many viewers participated and how those participants answered.
                  </ImageCaption>
                </Figure>
                <h4>Viewing & Sharing Poll Analytics</h4>
                <Figure>
                  <iframe
                    title="vimeo-player"
                    src="https://player.vimeo.com/video/665580995?h=4a58a5b757&badge=0&player_id=0&app_id=58479&autoplay=1&loop=1&autopause=0"
                    width="100%"
                    height="260"
                    frameBorder="0"
                    allow="autoplay"
                  />
                  <ImageCaption>
                    You can choose to view poll analytics privately and/or share these analytics
                    with viewers. Select <strong>View Analytics</strong> to see a breakdown of the
                    poll analytics in the control panel. Select <strong>SHARE ANALYTICS</strong> to
                    share a breakdown of the poll analytics with viewers and participants.
                    <br />
                    <br />
                    To stop sharing poll analytics with viewers, select{' '}
                    <strong>STOP SHARING</strong>.
                  </ImageCaption>
                </Figure>
              </>
            )}
            {selectedPollDocumentation === 'wordcloud' && (
              <>
                <Figure>
                  <Image src={Wordcloud1} />
                  <ImageCaption>
                    Fill in all necessary fields. You <strong>must</strong> provide a question. If
                    applicable, choose one or more options from the checklist to add additional
                    settings to the poll.
                    <br />
                    <br />
                    To save without any additional settings, select <strong>SAVE</strong>
                  </ImageCaption>
                </Figure>
                <h4>Choosing Additional Fields</h4>
                <Figure>
                  <Option>
                    <ImageCaption style={{ margin: '0 1rem' }}>
                      <strong>Allow multiple inputs</strong>
                      <br />
                      <br />
                      Allow participants to input more than one answer. Choose how many answers you
                      want participants to provide from the dropdown menu.
                    </ImageCaption>
                    <iframe
                      title="vimeo-player"
                      src="https://player.vimeo.com/video/664295563?h=a08b1933d3&badge=0&player_id=0&app_id=58479&autoplay=1&loop=1&autopause=0"
                      height="340"
                      frameBorder="0"
                      allow="autoplay"
                    />
                  </Option>
                  <Option>
                    <ImageCaption style={{ margin: '0 1rem' }}>
                      <strong>Include a poll description</strong>
                      <br />
                      <br />
                      You can provide a short description or instruction for participants if you
                      need to clarify a question.
                    </ImageCaption>
                    <iframe
                      title="vimeo-player"
                      src="https://player.vimeo.com/video/664309853?h=e31dc927a2&badge=0&player_id=0&app_id=58479&autoplay=1&loop=1&autopause=0"
                      height="340"
                      frameBorder="0"
                      allow="autoplay"
                    />
                  </Option>
                  <Option>
                    <ImageCaption style={{ margin: '0 1rem' }}>
                      <strong>Set character limit</strong>
                      <br />
                      <br />
                      Setting a character limit can help to keep a participant&apos;s answers short.
                      The character limit is applied to every text field.
                    </ImageCaption>
                    <Image src={Wordcloud2} style={{ maxWidth: '18.75rem' }} />
                  </Option>
                  <ImageCaption style={{ margin: '0 1rem' }}>
                    <br />
                    Select <strong>SAVE</strong>.
                  </ImageCaption>
                </Figure>
                <h4>Edit or Delete Polls</h4>
                <Figure>
                  <Image src={Wordcloud7} />
                  <ImageCaption>
                    The poll can then be found filed under Queued Polls. At anytime before opening a
                    poll you&apos;ve created, you can go back and make an edit.
                    <br />
                    <br />
                    <strong>Edit</strong> allows you to make changes to poll settings.{' '}
                    <strong>Delete</strong> allows you to permanently delete a poll, along with any
                    corresponding analytics.
                  </ImageCaption>
                </Figure>
                <h4>Opening a Poll to Viewers & Partcipants</h4>
                <Figure>
                  <Image src={Wordcloud8} />
                  <ImageCaption>
                    Select your newly created poll under Queued Poll, then select{' '}
                    <strong>OPEN POLL</strong> to display the poll to viewers and partcipants.
                  </ImageCaption>
                </Figure>
                <Figure>
                  <Option>
                    <ImageCaption>
                      Viewers will be presented with a question and one or more text fields to
                      provide their answers.
                    </ImageCaption>
                    <Image src={Wordcloud3} style={{ maxWidth: '18.75rem' }} />
                  </Option>
                  <Option>
                    <ImageCaption>
                      If a viewer decides to participate, they will need to at least provide one
                      answer before submitting.
                    </ImageCaption>
                    <Image src={Wordcloud4} style={{ maxWidth: '18.75rem' }} />
                  </Option>
                </Figure>
                <Figure>
                  <ImageCaption>
                    When you a ready to close a poll select <strong>CLOSE POLL</strong>. Once a poll
                    is closed it will then be filed under Closed Polls, analytics will be available
                    to show how many viewers participated and how those participants answered. You
                    will also be able to share a wordcloud of the submissions with participants.
                  </ImageCaption>
                </Figure>
                <h4>Viewing & Editing Poll Analytics</h4>
                <Figure>
                  <Option>
                    <ImageCaption>
                      Select <strong>View Analytics</strong> to see a breakdown of the poll
                      analytics in the control panel.
                    </ImageCaption>
                    <Image src={Wordcloud5} style={{ maxWidth: '18.75rem' }} />
                  </Option>
                  <Option>
                    <ImageCaption>
                      If for any reason you need to delete a submission, you can simply select{' '}
                      <strong>Delete</strong> next to the submission. Deleting a submission is
                      permanent and will remove any matching submissions.
                    </ImageCaption>
                    <iframe
                      title="vimeo-player"
                      src="https://player.vimeo.com/video/664325798?h=5fe3d86458&badge=0&player_id=0&app_id=58479&autoplay=1&loop=1&autopause=0"
                      height="340"
                      frameBorder="0"
                      allow="autoplay"
                    />
                  </Option>
                </Figure>
                <h4>Sharing Poll Analytics & Wordcloud</h4>
                <Figure>
                  <Image src={Wordcloud6} />
                  <ImageCaption>
                    Select <strong>SHARE ANALYTICS</strong> to share a breakdown of the poll
                    analytics with viewers and participants and display a wordcloud of the
                    submissions overlaying the livestream.
                    <br />
                    <br />
                    To stop sharing poll analytics with viewers, select{' '}
                    <strong>STOP SHARING</strong>.
                  </ImageCaption>
                </Figure>
              </>
            )}
          </FigureWrapper>
        </Content>
      </AnimatePresence>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.bg};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 6.75rem;
  h2 {
    margin: 0.5rem;
    padding: 0.5rem;
  }
  h3 {
    font-weight: normal;
    text-align: center;
    width: 80vw;
  }
  @media (min-width: ${breakpoints.lg}) {
    margin-top: 9.75rem;
    h3 {
      width: 50vw;
    }
  }
`;

const Content = styled(motion.div)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3rem 0;
`;

const Image = styled.img`
  width: 100%;
`;

const Option = styled.div`
  display: flex;
  margin: 1rem 0 0 0;
  @media only screen and (max-width: 520px) {
    flex-direction: column;
  }
`;

const ImageCaption = styled.figcaption`
  padding: 1rem 0 2rem 0;
  width: 100%;
  small {
    opacity: 70%;
  }
`;

const Figure = styled.figure`
  align-items: center;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  width: 60vw;
  @media only screen and (max-width: 1024px) {
    width: 90vw;
  }
`;

const FigureWrapper = styled.div`
  margin: 3rem;
  figure {
    margin: 2rem 0;
  }
`;

const PollTypeSelector = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;

export default PollDocumentation;
